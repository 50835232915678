<template>
  <v-list-item dense class="px-4">

    <v-list-item-avatar tile>
      <planete-icon width="24" height="24">{{ category.icon }}</planete-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ category.title }}
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex flex-row">
        <template
            v-for="(category, i) in notification.notificationDisplay.categories"
        >

          <template v-if="category.datas">
            <template v-for="(data, j) in category.datas">
              <div :key="'notif-cat'+i+j" class="mr-1"
                   v-if="notification.data.data[category.key]
                                && notification.data.data[category.key][data.key]">
                {{ notification.data.data[category.key][data.key] }}
              </div>
            </template>
          </template>

          <template v-else>
            <div :key="'notif-cat'+i" class="mr-1">{{ notification.data.data[category.key] }}</div>
          </template>

        </template>


        <v-spacer></v-spacer>

        <v-dialog v-model="showModal" width="75vw">
          <template
              #activator="{attrs, on}">
            <v-btn
                v-bind:attrs="attrs"
                v-on="on"
                x-small
                color="info"
            >Consulter
            </v-btn>
          </template>

          <v-card>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Informations du candidat</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="pa-4 d-flex flex-row flex-wrap">

              <div class="d-flex flex-column align-stretch pa-4" style="flex: 0 1 120px;">
                <img
                    v-if="notification.data.data && notification.data.data.fiches && notification.data.data.fiches.FICHIERPHOTO"
                    :src="'data:image/jpeg;base64, ' + notification.data.data.fiches.FICHIERPHOTO"
                    style="height: auto;width: 100%"
                    class="rounded elevation-2"
                />

                <div class="mt-4">
                  <v-select
                      v-model="selectedCandidate"
                      v-if="notification.data.similarities"
                      label="Candidats similaires détectés"
                      :items="getSimilarities"
                      item-text="label"
                      item-value="value"
                      solo
                      hide-details
                      item-color="color"
                  ></v-select>
                </div>

                <div class="mt-4">
                  <v-select
                      v-model="selectedProfile"
                      v-if="notification.data.profiles"
                      label="Profils associés"
                      :items="getProfiles"
                      item-text="label"
                      item-value="value"
                      solo
                      hide-details
                      item-color="color"
                  ></v-select>
                </div>

                <v-btn class="mt-4" color="error" @click="candidatAction(false)">Refuser le candidat</v-btn>
                <template
                    v-if="(!notification.data.similarities || selectedCandidate) && (!notification.data.profiles || selectedProfile)">
                  <v-btn class="mt-4" color="success" @click="candidatAction(true)">Accepter le candidat</v-btn>
                </template>
              </div>


                <div class="d-flex flex-row flex-wrap" style="flex: 1 1 75%; height: 70vh; overflow-y: auto;">
                  <vue-scroll>

                  <template
                    v-for="(category, i) in notification.detailsDisplay.categories"

                >

                  <template v-if="notification.data.data[category.key]">

                    <v-card :key="'notif-cat'+i" class="ma-4 flex">

                      <v-toolbar color="primary" class="font-weight-bold" dense dark>{{ category.label }}</v-toolbar>

                      <v-card-text class="pa-4">
                        <template v-if="category.datas">

                          <template v-if="category.type && category.type === 'list'">

                            <table style="width: 100%">
                              <thead>
                              <tr>
                                <th v-for="(data, j) in category.datas" :key="category.label+j">
                                  {{ data.label }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <template v-if="notification.data.data[category.key]">
                                <tr v-for="(data, k) in notification.data.data[category.key]" :key="data.toString()+k">
                                  <td v-for="(categoryData, j) in category.datas" :key="category.label+j">
                                    {{ data[categoryData.key] }}
                                  </td>
                                </tr>
                              </template>

                              </tbody>
                            </table>

                          </template>
                          <template v-else>

                            <template v-for="(data, j) in category.datas">

                              <div :key="'notif-cat'+i+j"
                                   class="py-1 px-2 d-flex flex-row align-space justify-space-between notification-interim-item">

                                <div class="pr-8 font-weight-bold" style="width: 50%">{{ data.label }}</div>

                                <div style="text-align: right;min-width: 200px">
                                  <template
                                      v-if="notification.data.data[category.key] && notification.data.data[category.key][data.key]">
                                    {{ notification.data.data[category.key][data.key] }}
                                  </template>
                                </div>

                              </div>
                            </template>

                          </template>


                        </template>

                      </v-card-text>

                    </v-card>
                  </template>

                </template>
                  </vue-scroll>

                </div>
            </v-card-text>

          </v-card>

        </v-dialog>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import NotificationsService from "@/Services/NotificationsService"
import axios from "axios"

export default {
  name: "ListCandidats",
  props: {
    notification: Object,
    category: Object
  },
  computed: {
    getSimilarities() {
      return [
        {
          label: "Nouveau candidat", value: -1, color: "green"
        },
        ...this.notification.data.similarities
      ]
    },
    getProfiles() {
      return this.notification.data.profiles
    }
  },
  data() {
    return {
      showModal: false,
      selectedCandidate: -1,
      selectedProfile: null
    }
  },
  methods: {
    candidatAction(accept) {

      let $this = this

      let actionUrl = this.notification.data[accept ? 'acceptUrl' : 'declineUrl']
      if (actionUrl[0] != "/") {
        actionUrl = "/" + actionUrl
      }
      if (this.selectedCandidate && this.selectedCandidate !== -1) {
        actionUrl += "&candidate=" + this.selectedCandidate
      }
      if (this.selectedProfile && this.selectedProfile !== -1) {
        actionUrl += "&profile=" + this.selectedProfile
      }

      axios
          .get(actionUrl)
          .then(response => {
            response = response.data
            if (response.success) {
              $this.$nSuccess(response.message)
            } else {
              $this.$nError(response.message)
            }
            $this.showModal = false
            $this.resetNotification()
          })

          .catch(error => {
            $this.$nError("Une erreur est survenue", error)
          })

    },
    resetNotification() {
      NotificationsService
          .getListCandidats()
          .then(async responseListCandidats => {
            if (responseListCandidats && responseListCandidats.list && responseListCandidats.list.length >= 0) {

              let category = {
                slug: "listCandidats",
                icon: "interimaire",
                title: this.$t("notifications.listCandidat.subtitle"),
                component: "list-candidats",
                height: 64
              }

              this.$store.commit("addNotification", {category, reset: true})

              for (let alert of responseListCandidats.list) {

                let alertToAdd = {
                  displayKeys: responseListCandidats.displayKeys,
                  data: alert
                }

                this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
              }
            }
          })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/main.sass';

$a: rgb(0, 0, 0);

table {
  border-collapse: separate;
  border-spacing: 0 .5em;
  cursor: default;
}

table tr td:first-child, table tr th:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

table tr td:last-child, table tr th:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

table tr td, table tr th {
  padding: .1em .5em;
  text-align: left;
}

table thead tr {
  background: rgba($a, .2);
}

.notification-interim-item:nth-child(odd),
table tbody tr:nth-child(odd) {
  border-radius: 4px;
  background: rgba($a, .05);
}

.notification-interim-item:hover, table tbody tr:hover {
  border-radius: 4px;
  background: rgba($a, .1);
}

</style>
